import { BsDownload } from 'react-icons/bs'
import { downloadSelector } from '@/atoms'
import { useRecoilState } from 'recoil'

const protocolData = new Map()
protocolData.set('iMessage', 'https://docs.getautomata.app/imessage/setup')
protocolData.set('WhatsApp', 'https://docs.getautomata.app/whatsapp/setup')

type Downloads = {
  nextStepFn?: () => void
}

export default function Downloads({nextStepFn}: Downloads) {

  const [downloads, setDownloads] = useRecoilState(downloadSelector)
  
  return (
    <>
      <table role="grid">
        <thead>
          <tr>
            <th scope="col"><strong>Operating System</strong></th>
            <th scope="col"><strong>Setup Guides</strong></th>
            <th scope="col"><strong></strong></th>
          </tr>
        </thead>
        <tbody>
          {
            downloads.map((d) => (
            <tr key={d.id}>
              <td>{d.name}</td>
              <td>
                {
                  !!d.protocols ?
                  <div className="grid">
                    {
                      d.protocols.map((p: any, index: number) => (
                        // <button className='small-btn' key={index}><small>{p}</small></button>
                        <div key={index}><a href={protocolData.get(p)} target='_blank'><small>{p}</small></a></div>
                      ))
                    }
                  </div> : 
                  <></>
                }
              </td>
              <td>
                <a href={`https://automata-releases.s3.us-west-004.backblazeb2.com/${d.url}`}>
                  <button>
                    <BsDownload /> Download
                  </button>
                </a>
              </td>
            </tr>
            ))
          }
        </tbody>
      </table>
      {
        nextStepFn ?
        <a href='#' role='button'
        onClick={(e) => {
          e.preventDefault()
          nextStepFn()
        }}
        >Next</a> : <></>
      }
    </>
  )
}
