import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { Navbar } from '../components'
import * as Atoms from '@/atoms'

type FormData = {
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  p0?: string,
  p1?: string,
}

type Profile = {
  nextStepFn?: () => void,
  requirePassword?: boolean,
}

export default function Profile({nextStepFn, requirePassword}: Profile) {

  const user = useRecoilValue(Atoms.userState)
  const [profile, setProfile] = useState<FormData>({})
  const [error, setError] = useState('')
  const [status, setStatus] = useState('')
  const supabase = useSupabaseClient()

  const handleSubmit = async () => {

    if (!profile.firstName) return setError('Missing First Name')
    if (!profile.lastName) return setError('Missing Last Name')
    if (!profile.email) return setError('Missing Email')
    if (!!profile.p0 && profile.p0 !== profile.p1) return setError('Password mismatch')
    if (requirePassword && !profile.p0) return setError('Missing Password')

    if (profile.email !== user.email) await supabase.auth.updateUser({email: profile.email})
    if (profile.p0) await supabase.auth.updateUser({password: profile.p0})
    if (profile.firstName || profile.lastName) 
      await supabase.from('users').update({
        first_name: profile.firstName,
        last_name: profile.lastName,
        full_name: `${profile.firstName} ${profile.lastName}`,
      })
      .eq('id', user.id)
    if(nextStepFn) {
      console.log('Going to step 2')
      nextStepFn()
    } else {
      setStatus('Profile updated')
    }
  }

  useEffect(() => {
    setError('')
    setStatus('')
  }, [profile])

	useEffect(() => {
    if (user) setProfile({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
    })
  }, [user])

  return (
    <>
      <p>
        <strong>Subscription Tier: </strong><kbd>{!!user.subscription ? 'Premium' : 'Free'}</kbd><br />
        {
          !!user.subscription ?
          <a href={`https://billing.stripe.com/p/login/cN2eZ18pZ0ZkdRm288`} target='_blank' className="text-sm text-blue-600">Manage your subscription here.</a> :
          <a href={`https://buy.stripe.com/dR69D63MAeVL1xK3cc?client_reference_id=${user.id}`} target='_blank' role="button">Upgrade here.</a>
        }
      </p>

      <form>
        <div className="grid">
          <label>
            First name
            <input type="text" id="firstname" name="firstname" placeholder='First Name' value={profile.firstName ?? ''}
            onChange={(e) => setProfile(old => ({ ...old, firstName: e.target.value }))}
            required />
          </label>

          <label>
            Last name
            <input type="text" id="lastname" name="lastname" placeholder="Last name" value={profile.lastName ?? ''} 
            onChange={(e) => setProfile(old => ({ ...old, lastName: e.target.value }))}
            required />
          </label>
        </div>
        <label>Email address</label>
        <input type="email" id="email" name="email" placeholder="Email address" value={profile.email ?? ''} 
        onChange={(e) => setProfile(old => ({ ...old, email: e.target.value }))}
        required />
        <div className="grid">
          <label>
            New Password
            <input type="password" id="p0" name="p0" placeholder={requirePassword ? 'Set your password' :'Leave blank to keep current password'} value={profile.p0 ?? ''}
            onChange={(e) => setProfile(old => ({ ...old, p0: e.target.value }))}
            required={!!requirePassword}
            />
          </label>

          <label>
            Re-enter New Password
            <input type="password" id="p1" name="p1" placeholder={requirePassword ? 'Set your password' :'Leave blank to keep current password'} value={profile.p1 ?? ''} 
            onChange={(e) => setProfile(old => ({ ...old, p1: e.target.value }))}
            required={!!requirePassword}
            />
          </label>
        </div>
        <a href='#' role='button'
        onClick={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
        >{ nextStepFn ? 'Next' : 'Submit' }</a>
      </form>
      {!!error && <p className="red">{error}</p>}
      {!!status && <p>{status}</p>}
    </>
  )
}
