import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import * as Atoms from '@/atoms'

export default function GoogleSheets() {
	const [showApi, setShowApi] = useState(true)
  const user = useRecoilValue(Atoms.userState)

  return (
    <div className='p-2'>
      <p>Use this API URL:</p>
      <div className="grid">
        <div><input readOnly value={`https://api.getautomata.app/api/send/${user.key_id}`} type={showApi ? 'password' : 'text'} /></div>
        <div>
          <a href='#'
            role='button'
            onClick={(e) => {
              e.preventDefault()
              setShowApi(!showApi)
            }}
          >
            Show
          </a>
        </div>
      </div>

      <div className="grid">
        <div><p>Clone this Google Sheet:</p></div>
        <div>
          <a target='_blank'
            href='https://docs.google.com/spreadsheets/d/14B1Bun5cCMIwI7WDjTFsqT0pfazrJC0cbDD8Il_ConU/'
            role='button'
          >
            V4 Model
          </a>
        </div>
      </div>
      <a href='https://docs.getautomata.app/integrations/googlesheet' target='_blank'>Instructions for use</a>
    </div>
  )
}