/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { checkPhoneEmail } from '@/lib'
import { Database } from '@/lib/database.types'
import * as Atoms from '@/atoms'

export default function FollowUpBoss() {

  const user = useRecoilValue(Atoms.userState)
  const supabase = useSupabaseClient<Database>()
  const [allInt, setAllInt] = useRecoilState(Atoms.integrationsState)
  const [integrations, setIntegrations] = useState<Atoms.Integrations>()
  const [integrationText, setIntegrationText] = useState('')
  const [phoneText, setPhoneText] = useState('')

  useEffect(() => {
    setIntegrations(allInt.filter(e => e.name === 'Follow Up Boss')[0])
  }, [allInt])

  const setNewIntegration = async (integrations: Atoms.Integrations) => {

    // Check phone number
    const { phoneNumber, error: checkPhoneError } = checkPhoneEmail(integrations?.data?.phone, 'US')
    if (checkPhoneError) {
      setPhoneText('Bad phone number!')
      return
    } else {
      integrations = {
        ...integrations,
        data: { 
          ...integrations?.data,
          phone: phoneNumber,
        }
      }
      setIntegrations(integrations)
    }

    // Check integration key
    const response = await fetch('/api/check_int_api', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        name: integrations?.name,
        key: integrations?.data?.api_key
      })
    })
    const body = await response.json()

    // Error if bad key
    if (body.status !== 200) {
      setIntegrationText('Bad key!')
      return
    }

    if (integrations.id) {
      const { error } = await supabase.from('user_integrations').update(integrations).eq('user_id', user.id).eq('name', 'Follow Up Boss').eq('active', true)
      if (error) console.log('error', error)
      else setIntegrationText('Saved. Please restart your Automata app to load the new settings.')
    } else {
      const { data, error } = await supabase.from('user_integrations').insert({
        ...integrations,
        active: true
      }).select()
      if (error) console.log('error', error)
      else {
        console.log('New Integration: ', data[0])
        setIntegrations(data[0])
        setAllInt(old => {
          if (old.filter(e => e.name === 'Follow Up Boss').length > 0) return old.map(o => {
              return (o.id === 'Follow Up Boss') ? data[0] : o 
            })
          else return [...old, data[0]]
        })
        setIntegrationText('Saved. Please restart your Automata app to load the new settings.')
      }
    }
  }

  return (
    <div className='p-2'>
      <label>
        API Key:
        <input value={integrations?.data?.api_key ?? ''} 
          type='text'
          aria-invalid={integrationText && !integrationText.includes('Saved.')? 'true': undefined}
          onChange={(e) => {
            setIntegrationText('')
            setIntegrations({
              ...integrations,
              name: 'Follow Up Boss',
              user_id: user.id,
              data: { 
                phone: integrations?.data?.phone,
                api_key: e.target.value 
              }
            })
          }} 
        />
      </label>
      <label>Phone Number:
        <input value={integrations?.data?.phone ?? ''} 
          type='text'
          aria-invalid={phoneText ? 'true': undefined}
          onChange={(e) => {
            setPhoneText('')
            setIntegrations({
              ...integrations,
              name: 'Follow Up Boss',
              user_id: user.id,
              data: { 
                phone: e.target.value ,
                api_key: integrations?.data?.api_key
              }
            })
          }}
        />
      </label>
      <div className="grid">
        <div>
          <a href="#" role="button" 
            onClick={(e) => {
              e.preventDefault()
              if (integrations) setNewIntegration(integrations)
            }}>
            Save
          </a>
          {!!phoneText && <p className="red">{phoneText}</p>}
          {!!integrationText && <p className="red">{integrationText}</p>}
        </div>
        <div style={{textAlign: 'end'}}>
          <p><a href={`https://billing.stripe.com/p/login/cN2eZ18pZ0ZkdRm288`} target='_blank' className="text-sm text-blue-600">Manage your subscription here.</a></p>
          <p><a href={`https://docs.getautomata.app/integrations/followupboss`} target='_blank' className="text-sm text-blue-600">Integration documentation.</a></p>
        </div>
      </div>
    </div>
  )
}