import date from 'date-and-time'
import { BsFillTrashFill } from 'react-icons/bs'
import { Database } from '@/lib'

type Prop = {
	deleteFn: (id: string) => Promise<void>,
	messages?: Database['public']['Tables']['messages']['Row'][],
	loading: boolean
}

export default function MessagesTable({
	deleteFn,
	messages,
	loading
}: Prop) {

  return (
		<table role="grid" aria-busy={loading}>
			<thead>
				<tr>
					<th scope="col"><strong>#</strong></th>
					<th scope="col"><strong>Phone Number</strong></th>
					<th scope="col"><strong>Message</strong></th>
					<th scope="col"><strong>Protocol</strong></th>
					<th scope="col"><strong>Schedule</strong></th>
					<th scope="col"></th>
				</tr>
			</thead>
			<tbody>
				{
					(messages) ?
					messages.map((m, index) => (
					<tr key={index}>
						<th scope="row">{index + 1}</th>
						<td>{m.phone_number}</td>
						<td>{m.message}</td>
						<td style={{textTransform: 'capitalize'}}>{m.protocol}</td>
						<td>{m.scheduled_send ? date.format(new Date(m.scheduled_send), 'YYYY/MM/DD HH:mm:ss') : 'Immediate'}</td>
						<td><button className='contrast outline small-btn' onClick={() => deleteFn(m.id)}><BsFillTrashFill /></button></td>
					</tr>
					)) : 
					<></>
				}
			</tbody>
		</table>
  )
}
