import { useState } from 'react'
import { useRecoilState } from 'recoil'
import * as Atoms from '@/atoms'

type Message = {
	message: string,
	phoneNumber: string,
}

export default function TestMessage() {
	const [newMsg, setNewMsg] = useState({
    message: '',
    phoneNumber: ''
  })
	const [apiProtocol, setApiProtocol] = useState('whatsapp')
	const [userAtom, setUserAtom] = useRecoilState(Atoms.userState)
	const [open, setOpen] = useRecoilState(Atoms.testMsgModal)

	const addMsg = async (newMsg: Message) => {
    let message = newMsg.message.trim()
    let phoneNumber = newMsg.phoneNumber.trim()
    if (message.length) {
      await fetch(`/api/send/${userAtom.key_id}/${apiProtocol}`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          phoneNumber,
          message
        }),
      })
    }
  }

  return (
		<>
			<dialog open={open}>
				<article>
					<header>
						<a aria-label="Close" className="close" onClick={() => setOpen(o => !o)}></a>
						<h3 className='m-0'>Test Message Send</h3>
					</header>
					<form>
						<label>
							Use this tool to test adding a message to the queue
							<textarea 
								rows={4}
								placeholder="Message"
								value={newMsg.message}
								onChange={(e) => {
									setNewMsg((msg) => ({ ...msg, message: e.target.value}))
								}}
							/>
						</label>

						<label>
							Protocol
							<select name="rounded w-full p-2" 
								onChange={(e) => {
									setApiProtocol(e.target.value)
								}}
								value={apiProtocol}
							>
								<option value="whatsapp">WhatsApp</option>
								<option value="imessage">iMessage</option>
							</select>
						</label>

						<label>
							Phone Number (country code required)
							<input
								className="rounded w-full p-2"
								type="text"
								placeholder="Phone Number"
								value={newMsg.phoneNumber}
								onChange={(e) => {
									setNewMsg((msg) => ({ ...msg, phoneNumber: e.target.value}))
								}}
							/>
						</label>

						<a href="#" role="button" onClick={(e) => {
							e.preventDefault()
							addMsg(newMsg).then(() => setOpen(o => !o))
						}}>
							Submit
						</a>
					</form>
				</article>
			</dialog>
		</>
	);
}