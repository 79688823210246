import { useEffect, useMemo } from 'react'
import { useSession, useSupabaseClient, useUser, User } from '@supabase/auth-helpers-react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import * as Atoms from '@/atoms'
import * as Hooks from '@/hooks'
import { Database } from '@/lib/database.types'

export default function InitData() {
  const supabaseClient = useSupabaseClient<Database>()
	const user = useUser()
	const [userAtom, setUserAtom] = useRecoilState(Atoms.userState)
	const [downloads, setDownloads] = useRecoilState(Atoms.downloadSelector)
	const setDownloadVersion = useSetRecoilState(Atoms.downloadVersion)
	const [integrations, setIntegrations] = useRecoilState(Atoms.integrationsState)

	const fetchUserData = async (user: User) => {
    let { data: keyData, error: keyError } = await supabaseClient.from('keys').select('*').eq('user_id', user.id).eq('status', 'active')
    if (keyError) console.log('error', keyError)
		let { data: userData, error: userError } = await supabaseClient.from('users').select('*').eq('id', user.id)
    if (userError) console.log('error', userError)

		if (userData)	setUserAtom({
			user,
			key_id: keyData ? keyData[0]?.id : '',
			...userData[0],
		})
		else setUserAtom({
			user,
			key_id: keyData ? keyData[0]?.id : '',
		})
  }

	const fetchIntegrations = async (user: User) => {
    let { data, error } = await supabaseClient.from('user_integrations').select('*').eq('user_id', user.id).eq('active', true)
    if (error) console.log('error', error)
    if (data) {
      console.log('Retrieved Integration: ', data)
      setIntegrations(data)
    }
  }

	useMemo(() => {
		if (user) {
			fetchUserData(user)
			fetchIntegrations(user)
		}
  }, [user])

	useMemo(() => {
		if (!downloads.length) {
			fetch('/api/downloads').then(async (response) => {
				const body = await response.json()
				if (body?.files) {
					setDownloads(body.files)
					setDownloadVersion(body.version)
				}
			})
		}
	}, [downloads])

  return (
		<>
		</>
	);
}