import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { AiFillSave } from 'react-icons/ai'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { Database } from '@/lib/database.types'
import * as Atoms from '@/atoms'

export default function ApiInt() {
  const [showApi, setShowApi] = useState(true)
  const [apiProtocol, setApiProtocol] = useState('whatsapp')
	const [webhookText, setWebhookText] = useState('')
  const [user, setUser] = useRecoilState(Atoms.userState)
  const supabase = useSupabaseClient<Database>()

  const setNewWebhook = async () => {
    const { error } = await supabase.from('users').update({ 
      app_settings_json: user.app_settings_json
    }).eq('id', user.id)
    if (error) console.log('error', error)
    else setWebhookText('Saved. Please restart your Automata app to load the new settings.')
  }

  return (
    <div className='p-2'>
      <p>
        Your API endpoint:
      </p>
      <div className='grid'>
        <div>
          <input readOnly value={`https://api.getautomata.app/api/send/${user.key_id}/${apiProtocol}`} type={showApi ? 'password' : 'text'} />
        </div>
        <div className='grid'>
          <div>
            <a href='#' role='button'
              onClick={(e) => {
                e.preventDefault()
                setShowApi(!showApi)
                setApiProtocol('whatsapp')
              }}
            >
              WhatsApp
            </a>
          </div>
          <div>
            <a href='#' role='button'
              onClick={(e) => {
                e.preventDefault()
                setShowApi(!showApi)
                setApiProtocol('imessage')
              }}
            >
              iMessage
            </a>
          </div>
        </div>
      </div>
      <p><a href='https://docs.getautomata.app/integrations/api-access' target='_blank' >API documentation</a></p>

      <p>
        Webhook for messages received:
      </p>
      <div className='grid'>
        <div>
          <input value={user?.app_settings_json?.user?.webhook ?? ''} type='text'
            onChange={(e) => {
              setWebhookText('')
              setUser(old => ({
                ...old,
                app_settings_json: {
                  ...old?.app_settings_json,
                  user: { ...old?.app_settings_json?.user, webhook: e.target.value }
                }
              }))
            }}
          />
        </div>
        <div>
          <a href='#'
            role='button'
            onClick={(e) => {
              e.preventDefault()
              setNewWebhook()
            }}
          >
            <AiFillSave /> Save
          </a>
        </div>
      </div>
      {!!webhookText && <p className="red">{webhookText}</p>}
      <a href='https://docs.getautomata.app/integrations/webhook' target='_blank' className="px-1 text-md text-blue-600">Webhook documentation</a>
    </div>
  )
}