/* eslint-disable react-hooks/exhaustive-deps */
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSession, useSupabaseClient, useUser, User } from '@supabase/auth-helpers-react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as Atoms from '@/atoms'
import { Database } from '@/lib/database.types'

const buttons = [
	// { id: 1, name: 'Message List', route: '/'},
	{ id: 2, name: 'Downloads', route: '/downloads'},
	{ id: 3, name: 'Integrations', route: '/integrations'},
]

export default function Navbar() {
  const supabaseClient = useSupabaseClient<Database>()
	const session = useSession()
	const router = useRouter()
	const user = useUser()
	const userAtom = useRecoilValue(Atoms.userState)
	const [buttons, setButtons] = useState([
		// { id: 1, name: 'Message List', route: '/'},
		{ id: 2, name: 'Downloads', route: '/downloads'},
		{ id: 3, name: 'Integrations', route: '/integrations'},
	])

	useEffect(() => {
    if (!session || !user) {
      router.push(`/login?redirect=${router.pathname}`)
    }
  }, [session, user])

	useEffect(() => {
    if (userAtom.id && !userAtom.onboarded) {
      router.push('/onboarding')
    }
		if (userAtom.org_id && userAtom.org_role === 'Admin') setButtons( o => [...o.filter(e => e.name !== 'Admin'), { id: 4, name: 'Admin', route: '/admin'}])
  }, [userAtom])

  return (
		<>
			<Head>
        <title>Dashboard | Automata</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
			<nav className="container-fluid">
				<ul>
					<li><Link href="/" className="contrast"><h1 className='m-0'>Automata</h1></Link></li>
					{/* <li><span onClick={() => console.log(router)}>Test</span></li> */}
				</ul>
				<ul>
					<li>
            <details role="list">
              <summary aria-haspopup="listbox" role="link" className={`${['/', '/messages/history'].includes(router.route) ? 'contrast': ''}`}>Messages</summary>
              <ul role="listbox">
                <li><Link href='/'>Queue</Link></li>
                <li><Link href='/messages/history'>History</Link></li>
                {/* <li><a href="#" data-theme-switcher="dark">Analytics</a></li> */}
              </ul>
            </details>
          </li>
					{
						buttons.map(b => (
							<li key={b.id}>
								<Link href={b.route} 
									className={b.route === router.route ? 'contrast': ''}
								>
									{b.name}
								</Link>
							</li>
						))
					}
					<li>
						<details role="list" dir="rtl">
							<summary aria-haspopup="listbox" role="link" className="secondary">Help</summary>
							<ul role="listbox">
								<li><a href="https://docs.getautomata.app/contact">Contact Us</a></li>
								<li><a href="https://docs.getautomata.app">Documentation</a></li>
							</ul>
						</details>
					</li>
					<li>
						<details role="list" dir="rtl">
							<summary aria-haspopup="listbox" role="link" className="secondary">{userAtom.first_name}</summary>
							<ul role="listbox">
								<li><Link href="/profile" className="contrast">Profile</Link></li>
								<li><a href="#" onClick={async (e) => {
									e.preventDefault()
									const { error } = await supabaseClient.auth.signOut()
									if (error) console.log('Error logging out:', error.message)
								}}>Sign Out</a></li>
							</ul>
						</details>
					</li>
				</ul>
			</nav>
		</>
	);
}