import { useRecoilValue } from 'recoil'
import * as Atoms from '@/atoms'

export default function PremiumGate() {

  const user = useRecoilValue(Atoms.userState)

  return (
    <div className='p-2' style={{ textAlign: 'center' }}>
			<h6 className="red">Premium subscription required.</h6>
			<a href={`https://buy.stripe.com/dR69D63MAeVL1xK3cc?client_reference_id=${user.id}`} target='_blank' role="button">Upgrade here.</a>
    </div>
  )
}