import Image from 'next/image'
import Link from 'next/link'
import { useRecoilValue } from 'recoil'
import { useState, Dispatch, SetStateAction } from 'react'
import * as Atoms from '@/atoms'
import { FollowUpBoss, PremiumGate, GoogleSheets, ApiInt, Productive } from '../components'

type Integrations = {
  nextStepFn?: () => void
}

type IntegrationModal = {
  child: JSX.Element, 
  type: string, 
  show: string, 
  setShow: Dispatch<SetStateAction<string>>,
  subtitle?: string
}

function IntegrationModal ({ child, type, show, setShow, subtitle }: IntegrationModal) {
  return (
    <dialog open={type === show}>
			<article style={{minWidth: '50%'}}>
        <header style={{marginBottom:0}}>
          <a aria-label="Close" className="close" onClick={() => setShow('')}></a>
          <hgroup style={{margin: 0}}>
            <h3>{type}</h3>
            <p>{subtitle}</p>
          </hgroup>
        </header>
        {child}
      </article>
    </dialog>
  )
}

export default function Integrations({nextStepFn}: Integrations) {

  const user = useRecoilValue(Atoms.userState)
  const [show, setShow] = useState('')


  return (
    <>
      <div className="grid">
        <div>
          <article style={{display: 'grid', justifyItems: 'center'}}>
            <h3>API Integration</h3>
            <div><button onClick={() => setShow('API')}>Setup</button></div>
            <IntegrationModal child={
              <ApiInt />
            } type='API' show={show} setShow={setShow} />
          </article>
        </div>
        <div>
          <article style={{display: 'grid', justifyItems: 'center'}}>
            <Image 
              src="/followupboss.png" 
              width={256} height={68} 
              alt="Follow Up Boss"
              style={{ padding: '2rem'}}
            />
            {
              user.subscription !== 'Premium' ?
              <PremiumGate /> :
              <div>
                <button onClick={() => setShow('Follow Up Boss')}>Setup</button>
                <IntegrationModal child={
                  <FollowUpBoss />
                } type='Follow Up Boss' show={show} setShow={setShow} />
              </div>
            }
          </article>
        </div>
        <div>
          <article style={{display: 'grid', justifyItems: 'center'}}>
            <Image 
              src="/hubspot.png" 
              width={256} height={68} 
              alt="Hubspot"
              style={{ padding: '2rem'}}
            />
            {
              user.subscription !== 'Premium' ?
              <PremiumGate /> :
              <>
                <Link href={`https://share.hsforms.com/1J3MgvGntRSWYkTGylmOXuge3x5s?integration=Hubspot&email=${user.email}`} target="_blank"><button>
                  Setup</button> </Link>
              </>
            }
          </article>
        </div>
      </div>

      <div className="grid">
        <div>
          <article style={{display: 'grid', justifyItems: 'center'}}>
            <Image 
              src="/googlesheets.png" 
              width={180} height={68} 
              alt="Google Sheets"
            />
            <div>
              <button onClick={() => setShow('Google Sheets')}>Setup</button>
              <IntegrationModal child={
                <GoogleSheets />
              } type='Google Sheets' show={show} setShow={setShow} />
            </div>
          </article>
        </div>
        <div>
          <article style={{display: 'grid', justifyItems: 'center'}}>
            <Image 
              src="/productive.png" 
              width={256} height={68} 
              alt="Productive"
              style={{ padding: '2rem'}}
            />
            {
              user.subscription !== 'Premium' ?
              <PremiumGate /> :
              <div>
                <button onClick={() => setShow('Productive')}>Setup</button>
                <IntegrationModal child={
                  <Productive />
                } type='Productive' show={show} setShow={setShow} />
              </div>
            }
          </article>
        </div>
        <div>
          <article style={{display: 'grid', justifyItems: 'center'}}>
            <Image 
              src="/salesforce.png" 
              width={120} height={68} 
              alt="Salesforce"
            />
            {
              user.subscription !== 'Premium' ?
              <PremiumGate /> :
              <div>
                <Link href={`https://share.hsforms.com/1J3MgvGntRSWYkTGylmOXuge3x5s?integration=Salesforce&email=${user.email}`} target="_blank"><button>
                  Setup</button> </Link>
              </div>
            }
          </article>
        </div>
      </div>

      <div className="grid">
        <div>
          <article style={{display: 'grid', justifyItems: 'center'}}>
            <Image 
              src="/zapier.png" 
              width={120} height={68} 
              alt="Zapier"
            />
            <div>
              <Link href={`https://share.hsforms.com/1J3MgvGntRSWYkTGylmOXuge3x5s?integration=Zapier&email=${user.email}`} target="_blank"><button>
                Setup</button> </Link>
            </div>
          </article>
        </div>
        <div>
          <article style={{display: 'grid', justifyItems: 'center'}}>
            <Image 
              src="/make.png" 
              width={120} height={68} 
              alt="Make"
            />
            <div>
              <Link href={`https://share.hsforms.com/1J3MgvGntRSWYkTGylmOXuge3x5s?integration=Make.com&email=${user.email}`} target="_blank"><button>
                Setup</button> </Link>
            </div>
          </article>
        </div>
        <div></div>
      </div>

      {
        nextStepFn ?
        <a href='#' role='button'
        onClick={(e) => {
          e.preventDefault()
          nextStepFn()
        }}
        >Finish Setup</a> : <></>
      }
    </>
  )
}
