import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import Link from 'next/link'
import * as Atoms from '@/atoms'

export default function Productive() {
	const [showApi, setShowApi] = useState(true)
  const user = useRecoilValue(Atoms.userState)

  return (
    <div className='p-2'>
      <p>Login to <Link href='https://portal.productive.app/addon.php' target='_blank'>Productive.ai</Link> and connect Automata using this API key:</p>
      <div className="grid">
        <div><input readOnly value={`${user.key_id}`} type={showApi ? 'password' : 'text'} /></div>
        <div>
          <a href='#'
            role='button'
            onClick={(e) => {
              e.preventDefault()
              setShowApi(!showApi)
            }}
          >
            Show
          </a>
        </div>
      </div>

      <a href='https://docs.getautomata.app/integrations/productive' target='_blank'>Instructions for use</a>
    </div>
  )
}